import React, { useState } from "react";
import styled from "styled-components";
import { Link, graphql, useStaticQuery } from "gatsby";
import { IoIosArrowForward } from "react-icons/io";
import { IoPlay, IoOpenOutline } from "react-icons/io5";
import { GatsbyImage } from "gatsby-plugin-image";

import { useModal } from "../components/useModal";

import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../components/util";
import { AuthorCard, CallToAction, Modal } from "../components/site";
import Layout from "../components/layout";
import Seo from "../components/seo";

const ContentRow = styled(Row)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% + 30px);
  }
`;

const WebinarContainer = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  span {
    max-width: 950px;
    width: 100%;
  }
`;

const WebinarCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(props) => props.theme.styles.shadow};
  padding: 30px;
  text-decoration: none;
  margin: 0px 0px 20px 0px;

  &.-noLink {
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
  }

  border-radius: ${(props) => props.theme.styles.borderRadius};

  .badge {
    &:hover {
      opacity: 0.9;
    }
    padding: 4px 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      color: white;
      margin-top: 10px;
    }
    svg {
      margin-left: 15px;
      font-size: 22px;
    }
  }

  h4 {
    color: ${(props) => props.theme.colours.darkBlue};
    font-size: 18px;
    font-weight: 400;
    b {
      font-weight: 700;
    }
  }
  h2 {
    font-size: 24px;
    padding: 10px 0px 0px 0px;
    font-weight: 500 !important;
    margin-right: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
    .badge {
      margin-top: 20px;
    }
  }
`;

const VideoCardContainer = styled.div`
  position: relative;
  box-shadow: ${(props) => props.theme.styles.shadow};
  border-radius: 20px;
  width: calc((100% / 3) - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    &.-full {
      width: 100%;
      padding-bottom: 0;

      .play-circle {
        height: 100px;
        width: 100px;

        svg {
          font-size: 40px;
        }
      }

      &:hover {
        .play-circle {
          height: 110px;
          width: 110px;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    border-radius: 20px;
  }

  &:hover {
    .play-circle {
      height: 80px;
      width: 80px;
    }
  }

  .play-circle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-decoration: none;
    bottom: 30px;
    right: 30px;
    height: 70px;
    width: 70px;
    border-radius: 100px;
    /* transform: translate(-50%, -50%); */
    background: #fff;
    box-shadow: ${(props) => props.theme.styles.shadow};
    transition: all ${(props) => props.theme.transitions.med};

    svg {
      font-size: 30px;
      color: ${(props) => props.theme.colours.dark};
    }
  }
`;

const Events = (props) => {
  var [embedLink, setEmbedLink] = useState("");
  var [embedTitle, setEmbedTitle] = useState("");
  const [itemModalOpen, setItemModalOpen] = useModal();

  function handleModal(url, title) {
    setItemModalOpen(true);
    setEmbedLink(url);
    setEmbedTitle(title);
  }

  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulTutorialVideo(
        filter: {
          videoTitle: {
            in: [
              "A Getting Started"
              "Adding new employees"
              "Connecting with Inland Revenue"
            ]
          }
        }
        sort: { fields: videoTitle, order: ASC }
      ) {
        edges {
          node {
            thumbnail {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            embedUrl
            topic
            videoTitle
          }
        }
      }
      allContentfulEvent(sort: { order: ASC, fields: time }) {
        edges {
          node {
            title
            time(formatString: "hh:mma, MMM DD, YY")
            removalDate
            url
            type
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="Events | Webinars & Videos | PayHero"
        pathname={props.location.pathname}
      />
      <Container className="secondary -textCenter">
        <Wrapper css={{ zIndex: "10" }}>
          <Box stackGap={70}>
            <Box stackGap={30}>
              <Box stackGap={10}>
                <h1 className="-fontLarge">PayHero’s Online Events</h1>
                <h3 className="-fontNormal">
                  Join the PayHero community and learn the key steps to getting
                  started and how to set up your PayHero account.
                </h3>
              </Box>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Box stackGap={60}>
            <h2 className="-textCenter">Upcoming Webinars</h2>
            <WebinarContainer>
              <span>
                {contentfulData.allContentfulEvent.edges
                  // .filter(function(edge) {
                  //   return new Date(edge.node.removalDate) > new Date();
                  // })
                  .map((edge, i) => {
                    return (
                      <WebinarCard key={i}>
                        <div>
                          <h4>
                            <b>{edge.node.time}</b>
                          </h4>
                          <h2 className="-fontBold">{edge.node.title}</h2>
                        </div>
                        {edge.node.url && (
                          <Button
                            to={edge.node.url}
                            className="darkBlue -lg"
                            externalLink
                            atag
                          >
                            Register Here
                            <IoOpenOutline
                              css={{ top: "2px", marginLeft: "5px" }}
                            />
                          </Button>
                        )}
                      </WebinarCard>
                    );
                  })}
              </span>
            </WebinarContainer>
          </Box>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={80} className="font-bump">
            <Box size={30} stackGap={45}>
              <Box stackGap={25}>
                <h4 className="-fontBold">Hosted by:</h4>
                <AuthorCard author="Alex" hideRole />
                <AuthorCard author="Lizzie" hideRole />
              </Box>
            </Box>
            <Box size={70} stackGap={60}>
              <Box stackGap={60}>
                <Box stackGap={25}>
                  <h2>Getting Started with PayHero</h2>
                  <h4>
                    The team at PayHero is on a mission to be the most compliant
                    and easy to use payroll software in New Zealand. Register
                    for a training webinar hosted by a member of our Customer
                    Success team & learn how to use PayHero efficiently.
                  </h4>
                  <h4>
                    In this introductory series, you’ll learn everything you
                    need to know about setting up your PayHero account, so you
                    can start paying your employees right.
                  </h4>
                </Box>
                <Box stackGap={25}>
                  <h3>Basic PayHero Account Set Up</h3>
                  <h4>
                    Everything you need to run your first pay - Employees, Pay
                    Cycles, Apps & Integrations
                  </h4>
                  <h4 className="-fontNormal">We’ll show you how to:</h4>
                  <ul>
                    <li>
                      <h4>Manage your notifications</h4>
                    </li>
                    <li>
                      <h4>Add Employees</h4>
                    </li>
                    <li>
                      <h4>Create Pay Cycles</h4>
                    </li>
                    <li>
                      <h4>Manage the Employee Portal and Mobile App</h4>
                    </li>
                    <li>
                      <h4>Integrate with IRD & Xero</h4>
                    </li>
                    <li>
                      <h4>Process a Pay</h4>
                    </li>
                  </ul>

                  <h4 className="-fontNormal">Upcoming Webinars</h4>
                  <ul>
                    {contentfulData.allContentfulEvent.edges
                      .filter(function (edge) {
                        return new Date(edge.node.removalDate) > new Date();
                      })
                      .map((edge, i) => {
                        if (edge.node.type === "Basic")
                          return (
                            <li key={i}>
                              <a target="_blank" href={edge.node.url}>
                                {edge.node.title} | {edge.node.time}{" "}
                                <IoOpenOutline />
                              </a>
                            </li>
                          );
                      })}
                  </ul>
                </Box>
                <Box stackGap={25}>
                  <h3>Advanced PayHero Account Set Up</h3>
                  <h4>
                    Get your payroll humming with Pay Items, Work, Teams &
                    Tracking Categories
                  </h4>
                  <h4 className="-fontNormal">We’ll show you how to:</h4>
                  <ul>
                    <li>
                      <h4>
                        Create different types of pay items and what they each
                        mean
                      </h4>
                    </li>
                    <li>
                      <h4>
                        Set up Work and how to assign to pay items to your work
                        categories
                      </h4>
                    </li>
                    <li>
                      <h4>Add Teams and assign managers</h4>
                    </li>
                    <li>
                      <h4>
                        Integrate with Xero and assign tracking categories to
                        your Teams and Work
                      </h4>
                    </li>
                  </ul>
                  <h4 className="-fontNormal">Upcoming Webinars</h4>
                  <ul>
                    {contentfulData.allContentfulEvent.edges
                      .filter(function (edge) {
                        return new Date(edge.node.removalDate) > new Date();
                      })
                      .map((edge, i) => {
                        if (edge.node.type === "Advanced")
                          return (
                            <li key={i}>
                              <a target="_blank" href={edge.node.url}>
                                {edge.node.title} | {edge.node.time}{" "}
                                <IoOpenOutline />
                              </a>
                            </li>
                          );
                      })}
                  </ul>
                </Box>
                {/* <Box stackGap={25}>
                  <h3>
                    Step 3: <i>Coming soon...</i>
                  </h3>
                </Box> */}
              </Box>
              <hr />
              <Box stackGap={25}>
                <h4 className="-fontBold">About PayHero</h4>
                <h4>
                  PayHero is perfect for Kiwi companies that want stress free
                  and compliant online payroll. Especially those with part time
                  and mobile workforces, who face unique challenges tracking
                  employee time accurately and calculating the right holiday
                  entitlements for their staff.
                </h4>
                <h4>
                  PayHero streamlines the employee timesheet process with
                  online, mobile, GPS or clock in/out options, delivering
                  accurate employee hours straight into payroll.
                </h4>
                <h4 className="-fontBold">
                  <Link className="link-arrow" to="/">
                    Find out more <IoIosArrowForward />
                  </Link>
                </h4>
              </Box>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <Box stackGap={60}>
            <Box className="-textCenter" stackGap={5}>
              <h2 className="h1">Want on-demand resources?</h2>
              <h4>
                Check out our{" "}
                <Link to="/tutorials">PayHero tutorial videos</Link> to get
                started now!
              </h4>
            </Box>
            <ContentRow isWrap justify="flex-start">
              {contentfulData.allContentfulTutorialVideo.edges.map(
                (edge, i) => {
                  return (
                    <VideoCardContainer
                      key={i}
                      onClick={() => {
                        handleModal(edge.node.embedUrl, edge.node.videoTitle);
                      }}
                    >
                      {edge.node.thumbnail && (
                        <GatsbyImage
                          image={edge.node.thumbnail.gatsbyImageData}
                          alt={edge.node.videoTitle + ` | PayHero Video Guide`}
                          title={
                            edge.node.videoTitle + ` | PayHero Video Guide`
                          }
                        />
                      )}
                      <div className="play-circle">
                        <IoPlay />
                      </div>
                    </VideoCardContainer>
                  );
                }
              )}
            </ContentRow>
          </Box>
        </Wrapper>
      </Container>
      <CallToAction />
      <Modal
        isActive={itemModalOpen}
        embedURL={embedLink + `?autoplay=1`}
        embedTitle={embedTitle + ` | PayHero Video Guide`}
        handleClose={() => setItemModalOpen(false)}
        isVideo
      />
    </Layout>
  );
};

export default Events;
